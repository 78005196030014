var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'change-password',
      "size": 'md',
      "title": 'Change password',
      "description": `Last changed ${_vm.formatDate(_vm.profileData.dateOfPasswordUpdate)}`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "input__relative",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('ba-text-field', {
    attrs: {
      "label": "Old password",
      "placeholder": "********",
      "required": "",
      "type": _vm.isOldPasswordVisible ? 'text' : 'password'
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.passwordFields.oldPassword.$touch();
      }
    },
    model: {
      value: _vm.passwordFields.oldPassword,
      callback: function ($$v) {
        _vm.$set(_vm.passwordFields, "oldPassword", $$v);
      },
      expression: "passwordFields.oldPassword"
    }
  }), _c('div', {
    class: _vm.submitted && _vm.$v.passwordFields.oldPassword.$error ? 'error-append-icon' : 'append--icon'
  }, [_c('b-icon', {
    staticClass: "mx-2 pointer",
    attrs: {
      "font-scale": "1.3",
      "icon": !_vm.isOldPasswordVisible ? 'eye-fill' : 'eye-slash-fill'
    },
    on: {
      "click": function ($event) {
        _vm.isOldPasswordVisible = !_vm.isOldPasswordVisible;
      }
    }
  })], 1)], 1), _vm.submitted && _vm.$v.passwordFields.oldPassword.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Old Password is required ")]) : _vm._e()]), _c('b-col', {
    staticClass: "mt-3 input__relative",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('ba-text-field', {
    attrs: {
      "label": "New password",
      "placeholder": "********",
      "required": "",
      "type": _vm.isNewPasswordVisible ? 'text' : 'password'
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.passwordFields.newPassword.$touch();
      }
    },
    model: {
      value: _vm.passwordFields.newPassword,
      callback: function ($$v) {
        _vm.$set(_vm.passwordFields, "newPassword", $$v);
      },
      expression: "passwordFields.newPassword"
    }
  }), _c('div', {
    class: _vm.submitted && _vm.$v.passwordFields.newPassword.$error ? 'error-append-icon' : 'append--icon'
  }, [_c('b-icon', {
    staticClass: "mx-2 pointer",
    attrs: {
      "font-scale": "1.3",
      "icon": !_vm.isNewPasswordVisible ? 'eye-fill' : 'eye-slash-fill'
    },
    on: {
      "click": function ($event) {
        _vm.isNewPasswordVisible = !_vm.isNewPasswordVisible;
      }
    }
  })], 1)], 1), _vm.submitted && _vm.$v.passwordFields.newPassword.$error ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" New Password is required ")]) : _vm._e()]), _c('b-col', {
    staticClass: "mt-3 input__relative",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('ba-text-field', {
    attrs: {
      "label": "Confirm new password",
      "placeholder": "********",
      "required": "",
      "type": _vm.isNewConfirmPasswordVisible ? 'text' : 'password'
    },
    on: {
      "blur": function ($event) {
        return _vm.$v.passwordFields.confirmPassword.$touch();
      }
    },
    model: {
      value: _vm.passwordFields.confirmPassword,
      callback: function ($$v) {
        _vm.$set(_vm.passwordFields, "confirmPassword", $$v);
      },
      expression: "passwordFields.confirmPassword"
    }
  }), _c('div', {
    class: [_vm.submitted && _vm.$v.passwordFields.confirmPassword.$error ? 'error-append-icon' : 'append--icon', _vm.$v.passwordFields.confirmPassword.sameAsPassword ? null : 'error-append-icon']
  }, [_c('b-icon', {
    staticClass: "mx-2 pointer",
    attrs: {
      "font-scale": "1.3",
      "icon": !_vm.isNewConfirmPasswordVisible ? 'eye-fill' : 'eye-slash-fill'
    },
    on: {
      "click": function ($event) {
        _vm.isNewConfirmPasswordVisible = !_vm.isNewConfirmPasswordVisible;
      }
    }
  })], 1)], 1), _vm.submitted && !_vm.$v.passwordFields.confirmPassword.required ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Confirm password is required. ")]) : _vm._e(), !_vm.$v.passwordFields.confirmPassword.sameAsPassword ? _c('div', {
    staticClass: "error"
  }, [_vm._v(" Passwords must be the same. ")]) : _vm._e()])], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-between align-items-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Cancel",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('modal-change-password');
      }
    }
  }), _c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "loading": _vm.loading,
      "text": "Save password",
      "variant": "warning"
    },
    on: {
      "click": _vm.validateForm
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }